<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <div id="appDiv">
      <router-view
        data-static-testid="src-router-view-appView"
        @setAppClasses="setAppClasses"
      />
    </div>
    <RenewTokenPrompt
      :active="shouldRenewToken && !isRenewTokenPostponed"
      @cancel="cancelRenewToken"
      title="Renew Token"
      maxWidth="400px"
      icon="warning"
      message="The authorization token of the Store should be renewed from Seller Central via EVA platform."
      confirmButtonIcon="external"
      cancelButtonText="Understand"
      cancelButtonColor="primary"
      :animated="true"
      :animation-duration="250"
      animation-type="fade"
      :hasConfirmButton="false"
      :closeOnClickOutside="false"
    >
    </RenewTokenPrompt>
    <MaintenanceBanner />
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js';
import jwt from '@/http/requests/auth/jwt/index.js';
import 'vue2-datepicker/index.css';
import RenewTokenPrompt from '@/components/utility/Prompt.vue';
import moment from 'moment';
import MaintenanceBanner from '@/components/utility/MaintenanceBanner';

export default {
  metaInfo() {
    return {
      title: 'Amazon Solution Provider Network',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            'Eva provides the best solutions for Amazon Sellers; easy setup, 24/7 Live Support, affordable prices. Sign up for a 15-day free trial for the fastest Amazon Repricer.',
        },
      ],
    };
  },
  data() {
    return {
      vueAppClasses: [],
    };
  },
  components: {
    RenewTokenPrompt,
    MaintenanceBanner,
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val);
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr');
    },
  },
  computed: {
    selectedStore() {
      return this.$store.getters['formManagement/selectedStore'];
    },
    isRenewTokenPostponed() {
      return this.$store.getters['renewToken/postponeObject']?.postponed;
    },
    shouldRenewToken() {
      if (!this.selectedStore) return false;
      let today = moment();
      let tokenCreatedDate = moment(
        this.selectedStore.sellerApiAuthCodeCreatedAt
      );
      let diff = today.diff(tokenCreatedDate, 'months');

      if (this.selectedStore.sellerApiTokenStatus || diff >= 11) {
        return true;
      }
      return false;
    },
  },
  methods: {
    checkPostponedRenewTokenPrompt() {
      if (this.isRenewTokenPostponed) {
        let postpone = this.$store.getters['renewToken/postponeObject'];
        let now = moment();
        let postponedAt = moment(postpone.postponedAt);
        let diff = now.diff(postponedAt, 'minutes');
        if (
          diff >= postpone.postponedForMinutes ||
          (postpone.postponedForStore &&
            postpone.postponedForStore !== this.selectedStore?.storeId) ||
          (postpone.postponedForMarketplace &&
            postpone.postponedForMarketplace !==
              this.selectedStore?.marketplaceId)
        ) {
          this.$store.dispatch('renewToken/resetPostponed');
        }
      }
    },
    cancelRenewToken() {
      this.$store.dispatch('renewToken/postpone', {
        minutes: 60 * 24,
        store: this.selectedStore.storeId,
        marketplace: this.selectedStore.marketplaceId,
      });
    },
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark');
        document.body.classList.add('theme-dark');
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark');
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark'))
          document.body.classList.remove('theme-dark');
        if (document.body.className.match('theme-semi-dark'))
          document.body.classList.remove('theme-semi-dark');
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);
      // Set --vh property
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  async created() {
    jwt.init();
    const dir = this.$vs.rtl ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleScroll);
    this.checkPostponedRenewTokenPrompt();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  provide() {
    return {
      store: this.$store,
      vs: this.$vs,
      router: this.$router,
      route: this.$route,
    };
  },
};
</script>

<style lang="scss">
.theme-dark
  .vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .vs-table--thead
  tr {
  background: #161a1d !important;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead tr {
  background: #e3e4e5 !important;
}

.th-center {
  .vs-table-text {
    justify-content: center;
    text-align: center;
  }
}

.w-body .vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 11px !important;
  }

  .w-body {
    th {
      font-size: 10px !important;
    }
    .vs-con-table .vs-con-tbody .vs-table--tbody-table {
      font-size: 10px;
    }
  }

  .v-select .vs__dropdown-toggle .vs__search {
    font-size: 10px !important;
  }

  .vs__selected {
    font-size: 10px;
  }

  .vs__dropdown-menu {
    li {
      font-size: 10px;
    }
  }

  .mx-input {
    font-size: 10px !important;
  }
}
.vs__actions .vs__clear {
  display: none !important;
}

.theme-dark .vx-breadcrumb {
  .feather-icon {
    svg {
      color: white !important;
    }
  }
}

.not-view-detail {
  .tr-expand {
    display: none !important;
  }
  .td-check.active-expanded .vs-icon {
    transform: translate(7px) rotate(-90deg) !important;
  }
}

.vx-card.po-list-filter-card {
  .vs-con-loading__container {
    .vx-card__body {
      padding-top: 0 !important;
    }
  }
}

.vs-sidebar:hover {
  z-index: 5000000 !important;
}
.prompt-footer-hidden.con-vs-dialog {
  .vs-dialog {
    footer {
      display: none !important;
    }
  }
}

.no-scroll {
  overflow: hidden !important;
}

.theme-dark #page-login .vx-card input {
  background: #262c49 !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.vs-radio--borde {
  border: 2px solid #32aeff !important;
  width: 97% !important;
  height: 98% !important;
}

.vs-radio--input:checked + .vs-radio .vs-radio--circle {
  background: none !important;
  border: 2px solid #32aeff;
}

.vs-radio--input:checked + .vs-radio .vs-radio--borde {
  opacity: 1 !important;
  transform: scale(0.5);
  z-index: 1;
  background: #32aeff;
}

.colorful-btn {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
  border-radius: 22px !important;
  transition: background-color 0.5s ease;

  .vs-button-text {
    color: white !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  &.vs-button-primary {
    background-image: linear-gradient(
      90deg,
      #a533c1,
      #bd27b2,
      #cf1ca2,
      #dd1792,
      #e71d82
    ) !important;
  }

  &.funding {
    padding: 0 !important;
    span {
      line-height: 13px !important;
    }
    .vs-button-text {
      display: flex;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      width: 165px;
    }
    &.vs-button-primary {
      background-image: linear-gradient(
        270deg,
        #2cd55b 0%,
        #4d943e 100%
      ) !important;
    }
    &:hover {
      span {
        color: white !important;
      }
    }
  }

  &.menu-in {
    padding: 0 !important;
    margin: 0 10px !important;
    .vs-button-text {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: auto;
    }

    &.small-btn {
      padding: 0 !important;
    }

    svg {
      fill: none !important;
    }
  }

  &.upgrade-btn.vs-button-primary {
    background-image: linear-gradient(
      90deg,
      #90ed7d 0%,
      #4d943e 100%
    ) !important;
    padding: 0.65rem 2rem 0.45rem 2rem !important;

    .vs-button-text {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-top: -4px;
      }
    }
  }

  &:hover {
    background: #fff !important;
    background-image: none !important;

    span {
      color: #393939 !important;
    }
  }

  span {
    font-size: 1.2rem !important;
    line-height: 0;
  }

  &.small-btn {
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    span {
      font-size: 15px !important;
    }
  }
}

@media only screen and (max-width: 430px) {
  .placeholder-fontsize {
    .input-span-placeholder,
    th {
      font-size: 0.5rem !important;
      margin-top: 0.4rem;
    }
  }
}

.vuesax-app-is-ltr .con-vs-dialog .vs-dialog header .vs-dialog-cancel {
  -webkit-transform: translate(0px, 0px) !important;
  transform: translate(0px, 0px) !important;
}

.con-vs-loading {
  z-index: 10000000000 !important;
}

.vs-tooltip.vs-tooltip-top.vs-tooltip-null {
  z-index: 10000000000 !important;
}

@media only screen and (max-width: 1600px) {
  .navbar-floating .vx-navbar-wrapper {
    padding: 0.3rem 0.6rem 2.2rem !important;
  }
  .router-view {
    padding: 0.6rem !important;
  }
  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    padding: 10px 8px !important;
  }
}

.theme-dark .v-nav-menu .feather-icon {
  color: #fff !important;
}
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  transform: none !important;
  background-color: rgba(204, 214, 235, 0.1) !important;
  .types-info-details-receive__details-table {
    .vs-con-tbody {
      .vs-table.vs-table--tbody-table {
        .tr-values {
          background: rgba(204, 214, 235, 0.1) !important;
          background-color: rgba(204, 214, 235, 0.1) !important;
        }
        .vs-table--thead {
          background-color: rgba(204, 214, 235, 0.1) !important;
          tr {
            background-color: rgba(204, 214, 235, 0.1) !important;
          }
        }
      }
    }
  }
  .warehouse-counting.types-info-details-receive__details-table
    .vs-table.vs-table--tbody-table {
    min-width: 318px !important;
    width: 318px;
    max-width: 318px;
  }
}

.return-management-order-parent {
  .vs-table--tbody-table
    .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
    background-color: transparent !important;
  }
  &.vs-con-table .vs-con-tbody {
    background: transparent !important;
  }
}

.vs-table--tbody-table .tr-values {
  tr {
    background-color: transparent !important;
  }
}

.return-management-order-detail.vs-con-table
  .vs-table--content
  .vs-con-tbody
  .vs-table--tbody-table.vs-table
  .vs-table--thead
  tr {
  background-color: #dbdada !important;
}
.theme-dark
  .return-management-order-detail.vs-con-table
  .vs-table--content
  .vs-con-tbody
  .vs-table--tbody-table.vs-table
  .vs-table--thead
  tr {
  background-color: #161a1d !important;
}
.vs__dropdown-menu {
  max-height: 300px !important;
}

.theme-dark .search-product-location .vs__dropdown-menu {
  li {
    color: #626262 !important;
    &:hover {
      color: #fff !important;
    }
  }
}
.vs-tooltip {
  z-index: 99999 !important;
  max-width: 270px !important;
  min-width: 150px;
  text-align: center;
}

.label {
  z-index: 1 !important;
}

.vx-navbar-wrapper.navbar-default {
  z-index: 52004 !important;
}

.vs-dropdown-menu {
  z-index: 1000000001 !important;
}

.vs-con-tbody
  .vs-table--tbody-table
  .tr-values
  .vs-table--td:not(.no-horizontal-padding) {
  padding: 24px 5px !important;
}

@media only screen and (max-width: 1600px) {
  .vs-con-tbody
    .vs-table--tbody-table
    .tr-values
    .vs-table--td:not(.no-horizontal-padding) {
    padding: 10px 4px !important;
  }
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
  padding-left: 5px !important;
}
.inventory-list-mapped-table.vs-con-table
  .vs-con-tbody
  .vs-table--tbody-table
  .vs-table--thead
  th {
  padding-left: 0px !important;
}
.con-vs-dropdown--menu {
  min-width: 160px;
  z-index: 98 !important;
}

.v-nav-menu .vs-sidebar--item .router-link-active {
  background: #21409a !important;
  box-shadow: none !important;
}

.theme-dark .vs__search {
  color: white !important;
  background-color: transparent !important;
}

.theme-dark .vs-input-primary.isFocus .vs-input--label {
  color: white !important;
}

.vue-select-up .vs__dropdown-menu {
  top: auto !important;
  bottom: 33px !important;
}

.theme-dark .vs-con-table .vs-con-tbody .vs-table--tbody-table tr {
  background: #212744;
}
.theme-dark .login-form {
  .input-span-placeholder,
  .vs-icon {
    color: white !important;
  }

  .vs-input-primary .vs-input--input:focus {
    border-color: white !important;
  }
}

.customizer-btn {
  z-index: 52005;
}

#theme-customizer .vs-sidebar {
  z-index: 1000002 !important;
}

.theme-dark .vue-form-wizard .wizard-nav-pills > li > a .stepTitle {
  color: #dae1e7 !important;
  font-weight: bold;
}

* {
  font-family: Hurme, sans-serif;
  font-weight: 600;
}

.vs-dialog header {
  display: none;
}

.size-medium .vs-dialog {
  max-width: 670px !important;
  max-height: 98% !important;
  position: relative;
}

.size-medium .vs-dialog footer {
  display: none !important;
}

.scroll .vs-dialog {
  overflow-y: auto !important;
}

@media only screen and (max-width: 991px) {
  .size-medium .vs-dialog {
    overflow-y: auto !important;
  }
}

.theme-semi-dark {
  .v-select .vs__selected {
    color: white;
  }
}

.notification-title {
  color: #161a1d;
  border-bottom: 1px solid #161a1d;
  margin-bottom: 4px;
}

.not-found-dis-color {
  color: #ffffff !important;
}

.vx-card__actions .vx-card__action-buttons span:nth-child(3) {
  display: none !important;
}

.theme-dark input:-webkit-autofill,
.theme-dark input:-webkit-autofill:hover,
.theme-dark input:-webkit-autofill:focus,
.theme-dark textarea:-webkit-autofill,
.theme-dark textarea:-webkit-autofill:hover,
.theme-dark textarea:-webkit-autofill:focus,
.theme-dark select:-webkit-autofill,
.theme-dark select:-webkit-autofill:hover,
.theme-dark select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #262c49 inset;
  box-shadow: 0 0 0px 1000px #262c49 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.upload-excel-popup .vs-dialog {
  width: 750px !important;
}

.scrollable-table {
  .vs-table {
    min-width: 700px;
    &--tbody {
      max-height: calc(100vh - 220px);
    }

    &--thead {
      position: sticky;
      top: 0;
      z-index: 500;
    }
  }
}
.invoices-table.scrollable-table {
  .vs-table {
    min-width: 1100px;
  }
}
.receive-status {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;

  &--received {
    background-color: rgb(35, 180, 66);
  }

  &--partially {
    background-color: rgb(243, 203, 72);
  }

  &--none {
    background-color: rgb(180, 35, 35);
  }

  &--overly {
    background-color: rgb(226, 104, 4);
  }
}

.table-action-btn {
  border-radius: 100%;
  transition: background-color 0.25s ease-out;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(.disabled):hover {
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
}

.detail-sticky-actions {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 16px;
  padding-bottom: 16px;
  position: sticky;
  bottom: 0;
  z-index: 999;
  background: rgb(248, 248, 248);
}

.flex-form-group {
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  &.flex {
    flex-flow: inherit;
    .v-select {
      .vs__selected-options {
        ::placeholder {
          color: #626262 !important;
          opacity: 0.7;
        }
      }
      .vs__dropdown-toggle {
        background: none;
      }
      .vs__dropdown-menu {
        background: white;
        li {
          color: #161a1d;
        }
      }
    }
  }
  label,
  .vs-input,
  .mx-input,
  .v-select {
    width: 100%;
  }

  .mx-input,
  .vs-input input,
  .vs-input input:focus {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .vs-input {
    &--input {
      padding: 6px !important;
      padding-left: 10px !important;
      &:focus {
        -webkit-box-shadow: unset;
        box-shadow: unset;

        & + .vs-input--placeholder {
          transform: unset;
        }
      }
    }
    &--placeholder {
      font-size: 1rem !important;
    }
  }
}

.mx-input,
.vs-input .vs-input--input,
.vs-input .vs-input--placeholder,
.v-select .vs__dropdown-toggle {
  height: 39px;
}

.vx-input-group {
  .search-input {
    input {
      border: 1px solid rgba(60, 60, 60, 0.26) !important;
    }
  }

  .search-btn {
    .vs-button-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #ededed;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.5rem;
  &:hover {
    background: #393939;
  }
}
::-webkit-scrollbar-corner {
  background-color: #ededed;
}

.no-horizontal-padding {
  &:not(:first-child) {
    padding-left: 0 !important;
  }
  &:not(:last-child) {
    padding-right: 0 !important;
  }
}

.on-hand-radio-menu {
  min-width: 200px;
  padding: 0px;
  margin-right: 15px;
  .vs-radio--label {
    width: 100%;
  }
  hr {
    color: #d9d9d9;
  }
}
.theme-dark .on-hand-radio-menu {
  hr {
    color: #535c8d;
  }
}
.detail-summary-table-inv {
  .vs-con-input-label {
    width: 140px;
    max-width: 140px;
  }
  .on-hand-radio-menu {
    min-width: 140px;
    width: 140px;
    margin-right: 56px;
    li {
      margin-bottom: 8px;
    }
  }
  .on-hand-radio-location-menu {
    min-width: 240px;
    width: 240px;
    margin-right: 20px;
    .vs-radio--label {
      width: 100%;
      max-width: 100%;
      min-width: 170px;
    }
    li {
      margin-bottom: 8px;
    }
    .hide-radio-btn {
      .vs-radio {
        display: none;
      }
    }
  }

  .location-input-container {
    min-width: 170px;
    ::placeholder {
      font-size: 10px;
    }
    .added-new-location {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
    }
    .vs__dropdown-menu {
      max-width: 170px;
      width: 170px;
      min-width: 170px;
      li {
        font-size: 11px;
      }
    }
  }
  .location-input-container.scroll-for-location-selectors {
    .vs__dropdown-menu {
      max-height: 50px;
      height: 50px;
      min-height: 50px;
    }
  }
}
.theme-dark .detail-summary-table-inv {
  .location-input-container {
    .text-primary {
      color: white !important;
      opacity: 0.9;
    }
  }
}
.theme-dark {
  .v-nav-menu .header-sidebar .feather-icon svg {
    color: white !important;
  }
  .vs-table--tbody-table .tr-values {
    border-top: 1px solid #121212;
  }

  .table-action-btn:not(.disabled):hover {
    background-color: rgba($color: #ffffff, $alpha: 0.2);
  }
  .detail-sticky-actions {
    background: #161a1d;
  }
  .flex-form-group {
    .mx-input,
    .vs-input input {
      background: #161a1d !important;
    }

    .mx-input {
      color: #fff;
    }
    .mx-icon-calendar,
    .mx-icon-clear {
      color: #fff;
    }

    .vs-input {
      &--placeholder {
        color: #999fbe;
      }
    }
    .v-select.vs--disabled .vs__dropdown-toggle .vs__actions {
      .vs__open-indicator {
        background-color: transparent;
      }
    }
  }
  .vx-input-group {
    .search-input {
      input {
        background: #161a1d !important;
        border: 1px solid transparent !important;
      }

      .vs-input--placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
  ::-webkit-scrollbar-track {
    background: #0d0344;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.5rem;
    &:hover {
      background: #ededed;
    }
  }
  ::-webkit-scrollbar-corner {
    background-color: #0d0344;
  }
  .location-input-container {
    .text-primary {
      color: white !important;
      opacity: 0.9;
    }
  }
}
.nav-store-label {
  margin: auto 0.5rem;
  font-size: 16px;
  font-weight: 600;
}

.export--filtered-btn {
  height: 36px;
  min-height: 36px;
  line-height: 0px;
  .vs-button-text.vs-button--text {
    display: flex;
    align-items: center;
  }
}

.inventory-image {
  width: 75px;
  min-width: 75px;
  height: 75px;
  border-radius: 5px;
}
.bus-prompt.con-vs-dialog.location-information-dialog.dialog-box .vs-dialog {
  @media only screen and (max-width: 1600px) and (min-width: 1250px) {
    min-width: 50% !important;
  }
  @media only screen and (max-width: 1250px) {
    min-width: 80% !important;
  }
}
.bus-prompt.con-vs-dialog.location-information-dialog.dialog-box.dialog-unit
  .vs-dialog {
  @media only screen and (max-width: 1700px) and (min-width: 1250px) {
    min-width: 60% !important;
  }
  @media only screen and (max-width: 1250px) {
    min-width: 80% !important;
  }
}
.bus-prompt.con-vs-dialog.location-information-dialog.dialog-unit .vs-dialog {
  @media only screen and (max-width: 1600px) and (min-width: 1250px) {
    min-width: 45% !important;
  }
  @media only screen and (max-width: 1250px) {
    min-width: 60% !important;
  }
}
.mx-datepicker-popup {
  z-index: 9999999 !important;
}
.transfer-product-info {
  .wareHouse-content {
    min-width: 70px;
    width: 70px;
    justify-content: center;
  }
  .form-group-dynamic {
    .sub-title {
      font-size: 12px;
    }
    .info-title {
      font-size: 11px;
    }
    .title {
      font-size: 13px;
    }

    .total-text {
      font-size: 13px;
    }
  }
}
.main-title {
  font-size: 15px;
}
.theme-dark .transfer-product-info {
  .form-group-content {
    .title,
    .desc {
      color: white;
    }
  }
  .form-group-dynamic {
    .sub-title,
    .vs__selected,
    .title,
    .info-title,
    .total-text {
      color: white !important;
    }
  }
  .con-vs-card.master-type-inventory-info {
    h6,
    .quantity-normal-head {
      color: white;
    }
    background: #1c3999 !important;
  }
  .add-new-location-transfer {
    color: white;
  }
}
.vs-button:not(.gradient-login):disabled {
  background: lightgrey !important;
  .vs-button-text {
    color: #302f2f;
  }
}
.theme-dark .vs-button:not(.gradient-login):disabled {
  background: rgb(4, 45, 225) !important;
  .vs-button-text {
    color: #ebecf0;
  }
}
.vs-button:not(.gradient-login) .vs-button-text {
  display: flex;
}
.adjustment-prompt.bus-prompt.con-vs-dialog .vs-dialog {
  min-width: 700px !important;
  max-width: 700px !important;

  .product-content__left-side {
    width: 100%;
  }
  .vs-con-input-label {
    width: 100%;
  }
  @media only screen and (max-width: 991px) and (min-width: 700px) {
    .product-content {
      flex-flow: unset !important;
      &__left-side {
        border-right: 1px solid #dfdfdf !important;
        border-bottom: 0px !important;
        margin-right: 10px;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    min-width: 90% !important;
    max-width: 99% !important;
  }
}
.increased-width-adjustment-prompt.adjustment-prompt.bus-prompt.con-vs-dialog
  .vs-dialog {
  max-width: 860px !important;
}
.adjustment-inputs {
  span {
    font-size: 0.8rem;
  }
  .vs-con-input {
    .vs-input--input.normal {
      max-height: 30px;
    }
  }
}
.user-management-table .header-table .con-input-search {
  margin-left: 0 !important;
}
.theme-dark .input-search.vs-table--search-input {
  background: #161a1d !important;
}
.td-check {
  padding: 0px;
  i {
    background: rgba(78, 149, 255, 0.1);
    padding: 5px;
    border-radius: 5px;
  }
}
.theme-dark {
  .v-nav-menu .vs-sidebar--item .router-link-active {
    background: #2d363c !important;
  }
}
textarea {
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
}
.inventory-list-mapped-table.vs-con-table.responsive-table.sku-management-tables
  .vs-con-tbody {
  th {
    .vs-table-text {
      justify-content: flex-start !important;
    }
  }
}
.theme-dark
  .inventory-list-mapped-table.vs-con-table.responsive-table.sku-management-tables
  .vs-con-tbody {
  .vs-table--tbody-table .vs-table--thead {
    background-color: transparent !important;
  }
}
</style>
