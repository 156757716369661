/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(Router);
Vue.use(VueMeta);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        /*{
          path: '/dashboard',
          redirect: '/dashboard/analytics'
        },*/
        {
          path: '/',
          name: 'dashboard photo',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/',
          },
        },
        {
          path: '/dashboard',
          name: 'warehouseDashboard',
          component: () => import('./views/components/dashboard/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/dashboard',
          },
        },
        {
          path: '/new-receive-order',
          name: 'newPO',
          component: () => import('./views/components/purchaseOrder/detail'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/new-receive-order',
            pageTitle: 'New Purchase Order',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/new-receive',
          name: 'newReceive',
          component: () => import('./views/components/receive/detail'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/new-receive',
            pageTitle: 'New Receive',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/receive-history',
          name: 'receiveList',
          component: () => import('./views/components/receive/list'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/receive-history',
            pageTitle: 'Packing List History',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        // {
        //   path: '/product-upload',
        //   name: 'productUpload',
        //   component: () => import('./views/components/product/upload'),
        //   meta: {
        //     rule: 'editor',
        //     permissionName: 'display_executive_dashboard',
        //     pathURL: '/product-upload',
        //     pageTitle: 'Product Upload',
        //     breadcrumb: [{ title: 'Home', url: '/dashboard' }],
        //   },
        // },
        {
          path: '/receive-order',
          name: 'po',
          component: () => import('./views/components/purchaseOrder/list'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/receive-order',
            pageTitle: 'Packing List Receive',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },

        {
          path: '/inventory-management',
          name: 'Inventory Management',
          component: () => import('./views/components/inventory/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/inventory-management',
            pageTitle: 'Inventory Management',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/sku-management',
          name: 'SKU Management',
          component: () => import('./views/components/skuManagement/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/sku-management',
            pageTitle: 'SKU Management',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/shipment',
          name: 'Shipment Management',
          component: () =>
            import('./views/components/shipmentTransaction/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/shipment',
            pageTitle: 'Shipment Management',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/inventory-list/shopify',
          name: 'Inventory Management',
          url: null,
          component: () => import('./views/components/inventory/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/inventory-list/shopify',
            pageTitle: 'Inventory Management',
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Shopify', active: true },
            ],
          },
        },
        {
          path: '/inventory-list/walmart',
          name: 'Inventory Management',
          url: null,
          component: () => import('./views/components/inventory/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/inventory-list/walmart',
            pageTitle: 'Inventory Management',
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Walmart', active: true },
            ],
          },
        },
        {
          path: '/return-receive',
          name: 'receive',
          component: () => import('./views/components/returnReceive/detail'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/return-receive',
            pageTitle: 'Receive',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/return-receive-list',
          name: 'removalOrders',
          component: () => import('./views/components/returnReceive/list'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/return-receive-list',
            pageTitle: 'Removal Orders',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/customer-return-receive-list',
          name: 'customerReturns',
          component: () =>
            import('./views/components/customerReturnReceive/list'),
          meta: {
            rule: 'editor',
            permissionName: 'display_executive_dashboard',
            pathURL: '/customer-return-receive-list',
            pageTitle: 'Customer Returns',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/inspection',
          name: 'inspection',
          component: () => import('./views/components/inspection/main'),
          meta: {
            rule: 'editor',
            permissionName: 'display_inspection',
            pathURL: '/inspection',
            pageTitle: 'Inspection',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/transfer',
          name: 'transfer',
          component: () => import('./views/components/transfer/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_transfer',
            pathURL: '/transfer',
            pageTitle: 'Transfer',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        // {
        //   path: '/shipstation',
        //   name: 'shipstation',
        //   component: () => import('./views/components/shipstation/main.vue'),
        //   meta: {
        //     rule: 'editor',
        //     permissionName: 'display_transfer',
        //     pathURL: '/shipstation',
        //     pageTitle: 'Shipstation',
        //     breadcrumb: [{ title: 'Home', url: '/dashboard' }],
        //   },
        // },
        {
          path: '/warehouse-counting',
          name: 'Warehouse Counting',
          component: () =>
            import('./views/components/warehouseCounting/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_warehouse_counting',
            pathURL: '/warehouse-counting',
            pageTitle: 'Warehouse Counting',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: '/settings',
          name: 'settings',
          component: () =>
            import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/dashboard' },
              { title: 'Settings', active: false },
            ],
            pageTitle: 'Settings',
            rule: 'editor',
            permissionName: 'display_settings',
            pathURL: '/settings',
          },
        },
        {
          path: '/invoice',
          name: 'invoice',
          component: () => import('./views/components/invoice/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_invoice',
            pathURL: '/invoice',
            pageTitle: 'Invoice',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/invoice-settings',
          name: 'invoice-settings',
          component: () =>
            import('./views/components/invoice/settings/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_invoice',
            pathURL: '/invoice-settings',
            pageTitle: 'Invoice Settings',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/product-management',
          name: 'product-management',
          component: () =>
            import('./views/components/productManagement/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_product-management',
            pathURL: '/product-management',
            pageTitle: 'Product Management',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
        {
          path: '/shipment-creation',
          name: 'shipment-creation',
          component: () =>
            import('./views/components/shipmentCreation/main.vue'),
          meta: {
            rule: 'editor',
            permissionName: 'display_shipment-creation',
            pathURL: '/shipment-creation',
            pageTitle: 'Shipment Creation',
            breadcrumb: [{ title: 'Home', url: '/dashboard' }],
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        // {
        //   path: '/welcome',
        //   name: 'page-welcome',
        //   component: () => import('@/views/pages/welcome.vue'),
        //   meta: {
        //     rule: 'editor',
        //   },
        // },
        // {
        //   path: '/new-user',
        //   name: 'new-user',
        //   component: () => import('@/views/pages/newuser.vue'),
        //   meta: {
        //     rule: 'editor',
        //   },
        // },
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor',
          },
        },
        {
          path: '/preparing',
          name: 'preparing',
          component: () => import('@/views/pages/preparing'),
          meta: {
            rule: 'editor',
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404',
    },
  ],
});

router.afterEach((_to) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach(async (to, _from, next) => {
  const apiToken = localStorage.getItem('accessToken');
  const isAdmin = localStorage.getItem('isAdmin');
  const isSuperAdmin = localStorage.getItem('isSuperAdmin');

  if (to.path === '/dashboard' && !isSuperAdmin) {
    return router.push({ path: '/' });
  }
  if (to.path === '/' && isSuperAdmin) {
    return router.push({ path: '/dashboard' });
  }

  if (
    to.path === '/welcome' ||
    to.path === '/login' ||
    to.path === '/new-user' ||
    to.path === '/forgot-password' ||
    to.path === '/reset-password' ||
    to.path === '/pages/error-404' ||
    to.path === '/pages/error-500' ||
    to.path === '/callback'
  ) {
    return next();
  }

  if (
    apiToken === null ||
    apiToken === undefined ||
    apiToken === '' ||
    (isAdmin == 'false' && to.path.includes('invoice'))
  ) {
    return router.push({ path: '/login' });
  }

  return next();
});

export default router;
